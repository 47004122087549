<template>
    <div id="equipmentConvert" ref="equipmentConvert" v-loading="loading" :element-loading-text="$t('alarm.a0')">
        <!-- 顶栏 -->
        <div class="header">
            <my-headertitle>
                <!-- 设备转换 -->
                {{$t('eqct.a32')}}
            </my-headertitle>
        </div>
        <div class="main" ref="main">
            <div class="main-top  dis-between">
                 <div class="dis-between">
                    <div>
                        <!-- 设备类型: -->
                         {{$t('eqct.a34')}}:
                        <a-select v-model="conDeviceType" style="width:200px;" @change="conDeviceTypeChange">
                            <a-select-option v-for="(item,index) in equipmentCotegories"  :key="index" :value="item.no">{{item.name}}</a-select-option>
                        </a-select>
                    </div>
                    <div class="main-top-item">
                        <a-button type="primary" @click="loadEndEquipmentList" :disabled="conDeviceType===''">{{$t('energy.search')}}</a-button>
                    </div>
                 </div>
               

                <div class="main-top-item" v-if="conDeviceType!==4 && endBrandList">
                    <!-- 终端品牌: -->
                     {{$t('eqct.a6')}}:
                    <a-select v-model="endBrand" style="width:200px;">
                        <a-select-option v-for="(item,index) in endBrandList"  :key="index" :value="item.no">{{item.name}}</a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="main-content" v-if="!conDeviceType || conDeviceType==1002 || conDeviceType==1003">
                <a-empty></a-empty>
            </div>
            <div class="main-content" v-if="conDeviceType>0">
                <div class="main-content-box" >
                    <div class="main-content-title dis-between">
                        <span>{{cateEndTitle}}</span>
                        <div v-if="conDeviceType<1001">
                            <a-button type="primary" @click="chooicEnd" :disabled="conDeviceType===''">{{chooicEndTitle}}</a-button>
                        </div>
                    </div>
                    <div class="main-content-area" v-if="conDeviceType===1001">
                        <div style="height:100%;width:100%;overflow:auto;">
                            <table class="mtable">
                                <thead>
                                    <tr>
                                        <th>
                                            <a-checkbox  v-model="barriCheckAll" @change="barriCheckAllChange" >全选</a-checkbox>
                                        </th>
                                        <th v-for="(item,index) in ncolumns7" :key="index">
                                            <a-checkbox v-if="item.dataIndex=='iow'"       v-model="iowCheckAll" @change="iowCheckAllChange">{{item.title}}</a-checkbox>
                                            <a-checkbox v-else-if="item.dataIndex=='vst'"  v-model="vstCheckAll" @change="vstCheckAllChange">{{item.title}}</a-checkbox>
                                            <a-checkbox v-else-if="item.dataIndex=='atd'"  v-model="atdCheckAll" @change="atdCheckAllChange">{{item.title}}</a-checkbox>
                                           <span v-else> {{item.title}}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row,index) in equipmentList" :key="index">
                                        <td>
                                            <a-checkbox  v-model="row.chk" @change="(e)=>{ barriCheckChange(e,row)}"></a-checkbox>
                                        </td>
                                        <td v-for="(item,k) in ncolumns7" :key="k" >
                                            <span v-if="item.dataIndex=='NO'">{{ item.dataIndex=='NO'?(index+1): row[item.dataIndex]}}</span>
                                            <a-checkbox v-else-if="item.dataIndex=='iow'"  v-model="row.iow"></a-checkbox>
                                            <a-checkbox v-else-if="item.dataIndex=='vst'"  v-model="row.vst"></a-checkbox>
                                            <a-checkbox v-else-if="item.dataIndex=='atd'"  v-model="row.atd"></a-checkbox>
                                             <a-input v-else-if="item.dataIndex=='equipmentName'"  v-model="row.equipmentName"></a-input>
                                            <span v-else>{{  row[item.dataIndex] }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="main-content-area" v-else-if="conDeviceType===4">
                        <a-row >
                            <a-col :span="8"  class="main-content-area-col1">
                                <div class="main-content-box1" >
                                    <div class="main-content-title">
                                        <!-- 第三方接口信息 -->
                                        {{$t('eqct.a65')}}
                                        </div>
                                    <div class="main-content-area1">
                                         <a-form-model
                                            ref="carRuleForm"
                                            :rules="carRules"
                                            :model="carform"
                                            :label-col="labelCol"
                                            :wrapper-col="wrapperCol">
                                            <!-- 终端品牌 -->
                                             <a-form-model-item ref="endBrand" :label="$t('eqct.a6')" prop="endBrand" >
                                                 <a-select v-model="carform.endBrand" @change="carEndBrandChange">
                                                    <a-select-option v-for="(item,index) in endBrandList"  :key="index" :value="item.no">{{item.name}}</a-select-option>
                                                </a-select>
                                            </a-form-model-item>
                                            <!-- 用户名 -->
                                            <a-form-model-item ref="apiAccount" :label="$t('eqct.a7')" prop="apiAccount" >
                                                <a-input v-model="carform.apiAccount" />
                                            </a-form-model-item>
                                            <!-- 密码 -->
                                             <a-form-model-item ref="apiPassword" :label="$t('eqct.a8')" prop="apiPassword" >
                                                <a-input v-model="carform.apiPassword" />
                                            </a-form-model-item>
                                            <!-- API路径 -->
                                            <a-form-model-item ref="apiURL" :label="$t('eqct.a9')" prop="apiURL" >
                                                <a-input v-model="carform.apiURL" />
                                            </a-form-model-item>
                                            <!-- 接口版本 -->
                                            <a-form-model-item ref="version" :label="$t('eqct.a10')" prop="version" >
                                                <a-input v-model="carform.version" />
                                            </a-form-model-item>
                                            <!-- 同步访问 启用 -->
                                             <a-form-model-item ref="appSync" :label="$t('eqct.a11')" prop="appSync" >
                                                <a-checkbox v-model="carform.appSync">{{$t('energy.enedata593')}}</a-checkbox>
                                            </a-form-model-item>
                                             <a-form-model-item ref="appID" label="APP ID" prop="appID" >
                                                <a-input v-model="carform.appID" />
                                            </a-form-model-item>
                                             <a-form-model-item ref="appKey" label="APP Key" prop="appKey" >
                                                <a-input v-model="carform.appKey" />
                                            </a-form-model-item>
                                         </a-form-model>
                                        
                                    </div>
                                </div>
                            </a-col>
                            <a-col :span="16" >
                                <a-tabs type="card" >
                                    <!-- 设备映射列表 -->
                                    <a-tab-pane key="1" :tab="$t('eqct.a42')">
                                        <a-table
                                            :columns="columns"
                                            :row-selection="rowSelection"
                                            :data-source="equipmentList"
                                            :pagination="pagination"
                                            :scroll="size"
                                            size="small"
                                            id="cvttable"
                                            ref="cvttable"
                                        >
                                            <!-- :row-key="record => record.id"  -->
                                            <template slot="opeat" slot-scope="text, record">
                                                <a-button
                                                    type="primary"
                                                    @click="remove(record)"
                                                    >{{$t('eqct.a26')}}</a-button
                                                    >
                                            </template>
                                            
                                            <template slot="carYardDeviceGuId" slot-scope="text, record">
                                                <a-tooltip placement="right"  :title="record.fdbmsg"  :visible="record.fdbvaild">
                                                    <a-select v-model="record.carYardDeviceGuId" style="width:200px;" :class="record.fdbcls">
                                                        <a-select-option v-for="(item,index) in carYardDevices" :key="index"  :value="item.deviceGuid">{{item.deviceName}}</a-select-option>
                                                    </a-select>
                                                </a-tooltip>
                                            </template>
                                        </a-table>
                                        <div class="main-content-area-fooler">
                                            <!-- 全部移除|移除转换|清除提示 -->
                                            <a-button type="primary" @click="removeAll" :disabled="equipmentList.length===0">{{$t('eqct.a25')}}</a-button>
                                            <a-divider type="vertical" />
                                            <a-button type="primary" @click="removeSelectedRows" :disabled="selectedRows.length===0">{{$t('eqct.a26')}}</a-button>   
                                            <a-divider type="vertical" />
                                            <a-button type="primary" @click="clearTooltips" :disabled="selectedRows.length===0">{{$t('eqct.a49')}}</a-button>

                                        </div>
                                    </a-tab-pane>
                                    <!-- 车场区域设置 -->
                                    <a-tab-pane key="2" :tab="$t('eqct.a41')" >
                                        <!-- 区域初始化 -->
                                        <a-button type="primary" @click="carAreaInit" :disabled="equipmentList.length===0">{{$t('eqct.a27')}}</a-button>

                                         <a-table :columns="carAreaColumns" :data-source="carAreaData">
                                            
                                            <span slot="list" slot-scope="list">
                                                <a-tag
                                                    v-for="tag in list"
                                                    :key="tag.no"
                                                    color="volcano"
                                                >
                                                    {{ tag.name }}
                                                </a-tag>
                                            </span>
                                          
                                        </a-table>
                                    </a-tab-pane>
                                    
                                </a-tabs>
                                
                            </a-col>
                        </a-row>
                    </div>
                    <div class="main-content-area" v-else>
                        <a-table
                            :columns="columns"
                            :row-selection="rowSelection"
                            :data-source="equipmentList"
                            :pagination="pagination"
                            :scroll="size"
                            size="small"
                            id="cvttable"
                            ref="cvttable"
                        >
                            <!-- :row-key="record => record.id" 移除转换  -->
                            <template slot="opeat" slot-scope="text, record">
                                <a-button
                                    type="primary"
                                    @click="remove(record)"
                                    >{{$t('eqct.a26')}}</a-button
                                    >
                            </template>
                                <template slot="equipmentName" slot-scope="text, record">
                                <a-tooltip placement="right"  :title="record.eqnmsg"  :visible="record.eqnvaild" :get-popup-container="getPopupContainer">
                                        <a-input v-model="record.equipmentName" :class="record.eqncls" />
                                </a-tooltip>
                                </template>
                                <template slot="faceName" slot-scope="text, record">
                                    <a-tooltip placement="right"  :title="record.fcnmsg"  :visible="record.fcnvaild">
                                        <a-input v-model="record.faceName" :class="record.fcncls" />
                                </a-tooltip>
                                </template>
                                <template slot="faceDeviceIp" slot-scope="text, record">
                                    <a-tooltip placement="right"  :title="record.fdpmsg"  :visible="record.fdpvaild">
                                        <a-input v-model="record.faceDeviceIp" :class="record.fdpcls" />
                                </a-tooltip>
                                </template>
                                <template slot="faceDeviceKey" slot-scope="text, record">
                                    <a-tooltip placement="right"  :title="record.fdkmsg"  :visible="record.fdkvaild">
                                        <a-input v-model="record.faceDeviceKey" :class="record.fdkcls" />
                                </a-tooltip>
                                </template>
                                <template slot="faceDeviceBrand" slot-scope="text, record">
                                    <a-tooltip placement="right"  :title="record.fdbmsg"  :visible="record.fdbvaild">
                                    <a-select v-model="record.faceDeviceBrand" style="width:100px;" :class="record.fdbcls">
                                        <a-select-option v-for="(item,index) in endBrandList" :key="index" :value="item.no">{{item.name}}</a-select-option>
                                    </a-select>
                                    </a-tooltip>
                                </template>
                                <template slot="tempt" slot-scope="text, record">
                                    <a-radio-group v-model="record.tempt">
                                        <a-radio :value="true" >
                                        <!-- 是 -->
                                        {{$t('eqct.a50')}}

                                        </a-radio>
                                        <a-radio :value="false">
                                        <!-- 否 -->
                                        {{$t('eqct.a51')}}
                                            
                                        </a-radio>
                                    </a-radio-group>
                                    
                                </template>

                                <template slot="vst" slot-scope="text, record">
                                    
                                    <a-checkbox v-model="record.vst">
                                        
                                    </a-checkbox>
                                </template>
                               
                                <template slot="iow" slot-scope="text, record">
                                    
                                    <a-checkbox v-model="record.iow">
                                        
                                    </a-checkbox>
                                </template>
                                <template slot="atd" slot-scope="text, record">
                                    
                                    <a-checkbox v-model="record.atd">
                                        
                                    </a-checkbox>
                                </template>

                        </a-table>
                         
                    </div>
                    <div class="main-content-area-fooler dis-between" v-if="conDeviceType!=4">
                       <div  v-if="conDeviceType<1001 ">
                            <a-button type="primary" @click="removeAll" :disabled="equipmentList.length===0">{{$t('eqct.a25')}}</a-button>
                            <a-divider type="vertical" />
                            <a-button type="primary" @click="removeSelectedRows" :disabled="selectedRows.length===0">{{$t('eqct.a26')}}</a-button>   
                            <a-divider type="vertical" />
                            <a-button type="primary" @click="clearTooltips" :disabled="selectedRows.length===0">{{$t('eqct.a49')}}</a-button>
                        </div>
                        <div v-else>
                            <a-button type="primary" @click="clearTooltips" :disabled="equipmentList.length===0">{{$t('eqct.a49')}}</a-button>
                        </div>
                        <div >
                             <a-button type="primary" @click="handleSubmit" :disabled="equipmentList.length===0">{{$t('energy.ok')}}</a-button>
                        </div>
                        
                    </div>
                    <div class="main-content-area-fooler dis-between" v-else-if="conDeviceType===4">
                        <div></div>
                         <div>
                             <a-button type="primary" @click="handleSubmit">{{$t('energy.ok')}}</a-button>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </div>
        <end-td :title="chooicEndTitle" :basic="chooicBasic"/>
    </div>
</template>
<script>
// iconDeviceType==1 tableTitles = "No.,关联终端类型,关联终端名称,人脸终端名称,序列号,设备IP,是否带测温,操作";
// iconDeviceType==4 tableTitles = "No.,关联终端类型,设备/终端名称,关联道闸终端,操作";
// iconDeviceType ==7 tableTitles = "No.,设备/终端类型,关联设备,设备/终端名称,出入口设备,访客设备,考勤设备";	
//  tableTitles = "No.,关联终端类型,设备/终端名称,操作";	
//
import headerTitle from "../../components/Title/headerTitle";
import endTableDialog from "./components/endTableDialog";
import { getConvertedEquipmentList,saveEquipmentConvert,removeEquipmentConvert,carParkAreaInit } from "../../api/door";
export default {
    data(){
        let normalString = (rule, value, callback) => {
        let regEn = /[`~!@$%^&*+<>?"{},\/;'[\]]/im;
    
            if(regEn.test(value)) {
                callback(new Error(this.$t('energy.enedata218')));
            } else {
                callback();
            }
        };
         let checkPending;
         let checkEndBrand = (rule, value, callback) => {
            clearTimeout(checkPending);
            // if (!value) {
            //     return callback(new Error('Please input the age'));
            // }
            checkPending = setTimeout(() => {
                
                if (value < 1) {
                    callback(new Error('请选择终端品牌。'));
                } else {
                    callback();
                }
                
            }, 100); 
        };
        return {
            
            loading:false,
            carYardDevices:[],
            equipmentCotegories:[
                {value:"", label:this.$t('energy.placeSelect')       ,children:undefined}			,
                {value:"5",label:"指静脉读卡器" ,children:undefined}    ,
                {value:"2",label:"二维码终端"   ,children:[{value:0,label:this.$t('energy.placeSelect')},{value:11,label:"微光"}]}      ,
                {value:"1",label:"人脸终端"     ,children:[{value:0,label:this.$t('energy.placeSelect')},{value:1,label:"宇泛"},{value:2,label:"奇智"},{value:3,label:"海康"}]}        ,
                {value:"3",label:"消费终端"     ,children:undefined}        ,
                {value:"4",label:"道闸设备"     ,children:[{value:0,label:this.$t('energy.placeSelect')},{value:21,label:"捷顺"},{value:22,label:"安居宝"}]}        ,
                {value:"6",label:"闸机设备"     ,children:undefined}        ,
                {value:"7",label:"设备类型设置" ,children:undefined}    ,
            ],
            conDeviceType:'',
            endBrandList:undefined,
            endBrand:undefined,
            equipmentCotegoryMap:undefined,
            columns:undefined,
            data:[],
            pagination: {
                total: 0, //数据总数
                pageSize: 100, //每页中显示10条数据
                showTotal: (total) => `${total} ${this.$t("attendance.a11")}`, //分页中显示总的数据
                showQuickJumper: true, //是否可以快速跳转至某页
                defaultCurrent: 1, //默认当前页面数
                hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
                onShowSizeChange: (current, pageSize) => {
                this.pagination.defaultCurrent = 1;
                this.pagination.pageSize = pageSize;
                },
                // 改变每页数量时更新显示
                onChange: (current, size) => {
                this.pagination.defaultCurrent = current;
                this.pagination.pageSize = size;
                },
            },
            ncolumns:[
                {
                    id:"1",
                    title: "NO.",
                    dataIndex:"NO",
                    customRender: (text, record, index) =>
                        `${
                        (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
                        index +
                        1
                        }`,
                    align: "center",
                    width: 60,
                },
                {
                    title: this.$t('eqct.a21'),//'关联终端类型',
                    dataIndex: 'memberTypeLabel',
                    width: 200,
                    // fixed: "left",
                },
                {
                    title: this.$t('eqct.a22'),//'设备/终端名称',
                    dataIndex: 'equipmentName',
                    scopedSlots: { customRender: "equipmentName" },
                    // width: 85,
                    // fixed: "left",
                },
                {
                    title: this.$t('visitorBookingRecord.a48'),//'操作',
                    dataIndex: 'opeat',
                    scopedSlots: { customRender: "opeat" },
                    width: 120,
                    // fixed: "left",
                }
            ],
            ncolumns1:[
                {
                    id:"1",
                    title: "NO.",
                    dataIndex:"NO",
                    customRender: (text, record, index) =>
                        `${
                        (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
                        index +
                        1
                        }`,
                    align: "center",
                    width: 60,
                },
                {
                    title: this.$t('eqct.a21'),//'关联终端类型',
                    dataIndex: 'memberTypeLabel',
                    // width: 85,
                    // fixed: "left",
                },
                {
                    title: this.$t('eqct.a36'),//'关联终端名称',
                    dataIndex: 'equipmentName',
                    // width: 85,
                    // fixed: "left",
                },
                {
                    title: this.$t('eqct.a37'),//'人脸终端名称',
                    dataIndex: 'faceName',
                    scopedSlots: { customRender: "faceName" },
                    // width: 85,
                    // fixed: "left",
                },
                {
                    title: this.$t('eqct.a38'),//'序列号',
                    dataIndex: 'faceDeviceKey',
                    scopedSlots: { customRender: "faceDeviceKey" },
                    // width: 85,
                    // fixed: "left",
                },
                {
                    title: this.$t('eqct.a17'),//'设备IP',
                    dataIndex: 'faceDeviceIp',
                    scopedSlots: { customRender: "faceDeviceIp" },
                    // width: 85,
                    // fixed: "left",
                },
                 {
                    title: this.$t('eqct.a39'),//'设备品牌',
                    dataIndex: 'faceDeviceBrand',
                    scopedSlots: { customRender: "faceDeviceBrand" },
                    // width: 85,
                    // fixed: "left",
                },
                {
                    title: this.$t('eqct.a40'),//'是否带测温',
                    dataIndex: 'tempt',
                    scopedSlots: { customRender: "tempt" },
                    // width: 85,
                    // fixed: "left",
                },
                {
                    title: this.$t('visitorBookingRecord.a48'),//'操作',
                    dataIndex: 'opeat',
                    scopedSlots: { customRender: "opeat" },
                    // width: 85,
                    // fixed: "left",
                }
            ],
            ncolumns4:[
                {
                    id:"1",
                    title: "NO.",
                    dataIndex:"NO",
                    customRender: (text, record, index) =>
                        `${
                        (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
                        index +
                        1
                        }`,
                    align: "center",
                    width: 60,
                },
                {
                    title: this.$t('eqct.a21'),//'关联终端类型',
                    dataIndex: 'memberTypeLabel',
                    // width: 85,
                    // fixed: "left",
                },
                {
                    title: this.$t('eqct.a22'),//'设备/终端名称',
                    dataIndex: 'doorName',
                    // width: 85,
                    // fixed: "left",
                },
                 {
                    title: this.$t('eqct.a23'),//'关联道闸终端',
                    dataIndex: 'carYardDeviceGuId',
                    scopedSlots: { customRender: "carYardDeviceGuId" },
                    // width: 85,
                    // fixed: "left",
                },
                {
                    title: this.$t('visitorBookingRecord.a48'),//'操作',
                    dataIndex: 'opeat',
                    scopedSlots: { customRender: "opeat" }
                }
            ],
            ncolumns7:[
                {
                    id:"1",
                    title:'NO.',
                    dataIndex:"NO",
                    customRender: (text, record, index) =>
                        `${
                        (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
                        index +
                        1
                        }`,
                    align: "center",
                    width: 60,
                },
                {
                    title: this.$t('eqct.a45'),//'设备/终端类型',
                    dataIndex: 'memberTypeLabel',
                    // width: 85,
                    // fixed: "left",
                },
                {
                    title: this.$t('eqct.a44'),//'关联设备',
                    dataIndex: 'placeEquipmentName',
                    // width: 85,
                    // fixed: "left",
                },
                {
                    title: this.$t('eqct.a22'),//'设备/终端名称',
                    dataIndex: 'equipmentName',
                    scopedSlots: { customRender: "equipmentName" },
                    // width: 85,
                    // fixed: "left",
                },
                {
                    title:  this.$t('eqct.a46'),//'出入口设备',
                    dataIndex: 'iow',
                    scopedSlots: { customRender: "iow"},
                    align: "center",
                    // width: 85,
                    // fixed: "left",
                },
                {
                    title: this.$t('eqct.a47'),//'访客设备',
                    dataIndex: 'vst',
                    scopedSlots: { customRender: "vst" },
                    align: "center",
                    // width: 85,
                    // fixed: "left",
                },{
                    title: this.$t('eqct.a48'),//'考勤设备',
                    dataIndex: 'atd',
                    scopedSlots: { customRender: "atd" },
                    align: "center",
                    // width: 85,
                    // fixed: "left",
                }
            ],

            endTableDialogVisible:false,
            chooicEndTitle:this.$t('eqct.a20'),//'选择门',
            chooicEndAuthTitle:this.$t('eqct.a35'),//'选择认证终端',
            cateEndTitle:this.$t('eqct.a34'),//"认证终端列表",
            chooicBasic:{
                action:0,
                clientId:this.$store.getters.clientId,
                sitegrId:this.$store.getters.sitegrId,
                siteId:this.$route.query.id,
                tenantId: 0,
                ends:[]
            },
            chooicEndAction:0,
            size:{y:400},
            equipmentList:[],
            selectedRowKeys:[],
            selectedRows:[],
            checkType:'checkbox',
            barriCheckAll:false,
            iowCheckAll:false,
            atdCheckAll:false,
            vstCheckAll:false,
            apiURLVail:{
                fcncls:'',
                fcnvaild:false,
                fcnmsg:'',
            },
            carRules:{
                apiAccount:     [{ required: true, message: this.$t('eqct.a52'),//'请输入第三方接口账户。',
                                     trigger: 'blur' },
                                 { min: 0, max: 200, message: this.$t('energy.enedata221'), trigger: 'blur' },
                                 { validator:normalString, trigger: 'blur' },
                                ],
                apiPassword:    [{ required: true, message: this.$t('eqct.a53'),//'请输入第三方接口账户密码。',
                                    trigger: 'blur' },
                                 { min: 0, max: 200, message: this.$t('energy.enedata221'), trigger: 'blur' },
                                 { validator:normalString, trigger: 'blur' },
                                ],
                apiURL:         [{ required: true, message:this.$t('eqct.a54'),// '请输入第三方接口地址。', 
                                    trigger: 'blur' },
                                 { min: 0, max: 200, message: this.$t('energy.enedata221'), trigger: 'blur' },
                                //  { validator:normalString, trigger: 'blur' },
                                ],
                appID:          [,
                                 { min: 0, max: 200, message: this.$t('energy.enedata221'), trigger: 'blur' },
                                 { validator:normalString, trigger: 'blur' },
                                ],
                appKey:         [,
                                 { min: 0, max: 200, message: this.$t('energy.enedata221'), trigger: 'blur' },
                                 { validator:normalString, trigger: 'blur' },
                                ],
                appSync:        [],
                endBrand:       [{ required: true, message:this.$t('eqct.a55'),// '请选择终端品牌。',
                                        trigger: 'change' },{ validator: checkEndBrand, trigger: 'change' }],
                version:        [{ required: true, message: this.$t('eqct.a56'),//'请输入第三方接口版本。', 
                            trigger: 'blur' },],
            },
            carform:{
                apiAccount:'',
                apiPassword:'',
                apiURL:'',
                appID:'',
                appKey:'',
                appSync:false,
                endBrand:0,
                version:'1',
            },
            labelCol: { span: 10 },
            wrapperCol: { span: 14 },
            carAreaColumns:[
                {
                    title: 'No.',
                    dataIndex: 'no',
                    key: 'no',
                },
                {
                    title:this.$t('eqct.a28'),//'区域名称',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: this.$t('eqct.a29'),//'设备列表',
                    key: 'list',
                    dataIndex: 'list',
                    scopedSlots: { customRender: 'list' },
                }
            ],
            carAreaData:[],
        }
    },
    mounted(){
        this.columns = this.ncolumns;
        this.loadEquipmentCategory();
    },
    activated(){
        console.log('activated',this.equipmentCotegoryMap);
    },
    
    methods:{
        carEndBrandChange(e){
            this.endBrand = e;
        },
        carAreaInit(){
            let param = {
                action: 0,
                clientId: this.$store.getters.clientId,
                sitegrId: this.$store.getters.sitegrId,
                siteId: this.$route.query.id
            }
            this.loading = true;
            carParkAreaInit(param)
            .then((res) => {
                console.log("Load Converted Equipment List>>>",res);
                this.carAreaData = res.data.carYardAreas;
                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
        loadEquipmentCategory(){
            let param = {
                action: 0,
                clientId: this.$store.getters.clientId,
                sitegrId: this.$store.getters.sitegrId,
                siteId: this.$route.query.id
            }
            this.loading = true;
            getConvertedEquipmentList(param)
            .then((res) => {
                console.log("Load Converted Equipment List>>>",res);
                this.equipmentCotegories = res.data.tabData;
                this.equipmentCotegoryMap = new Map();
                for (let i = 0; i < this.equipmentCotegories.length; i++) {
                    let element = this.equipmentCotegories[i];

                    this.equipmentCotegoryMap.set(element.no,element.children);
                }
                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
        loadEndEquipmentList(){
            if( this.conDeviceType ==='')
               return;
            let param = {
                action: 1,
                clientId: this.$store.getters.clientId,
                sitegrId: this.$store.getters.sitegrId,
                siteId: this.$route.query.id,
                iconDeviceType: this.conDeviceType,
            }
            this.loading = true;
            this.equipmentList = [];
            console.log("Load Converted Equipment param>>>",param);
            getConvertedEquipmentList(param)
            .then((res) => {
                console.log("Load Converted Equipment List>>>",res);
                this.endBrand = res.data.brand;
                this.carform.endBrand       = res.data.brand;
                

                if(this.conDeviceType===4){
                    this.carAreaData = res.data.carYardAreas;
                
                    if(res.data.carYardDevices){
                        this.carYardDevices = res.data.carYardDevices;
                    }
                    if(res.data.carYardInfo){
                       
                        this.carform.apiAccount		= res.data.carYardInfo.username;
                        this.carform.apiPassword	= res.data.carYardInfo.password;
                        this.carform.apiURL			= res.data.carYardInfo.url;
                        this.carform.appID			= res.data.carYardInfo.appId;
                        this.carform.appKey			= res.data.carYardInfo.key;
                        this.carform.appSync		= res.data.carYardInfo.issend;
                        this.carform.version        = res.data.carYardInfo.version;
                    }
                }else{
                    
                }
                this.equipmentList = res.data.data;
                this.equipmentList.forEach(row => {
                    if(this.conDeviceType===1){
                        row.fcncls = "";
                        row.fcnvaild = false;
                        row.fcnmsg = '';
                        row.fdkcls = "";
                        row.fdkvaild = false;
                        row.fdkmsg = '';
                        row.fdpcls = "";
                        row.fdpvaild = false;
                        row.fdpnmsg = '';
                        row.fdbcls = "";
                        row.fdbvaild = false;
                        row.fdbmsg = '';
                    } else {
                        row.eqncls = "";
                        row.eqnvaild = false;
                        row.eqnmsg = '';
                        
                    }
                });
                this.loading = false;
                
            })
            .catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
        conDeviceTypeChange(e,t){
            console.log('conDeviceTypeChange',e,typeof e)
            this.selectedRowKeys = [];
            this.selectedRows = [];
            this.endBrandList = this.equipmentCotegoryMap.get(e);
            this.endBrand = '';
            
            this.chooicEndAction  = 2;
            this.chooicEndTitle = this.$t('eqct.a35');//'选择认证终端';
            this.cateEndTitle   = this.$t('eqct.a34');//'认证终端列表';
            if(e===1){
                this.columns = this.ncolumns1;
            } else if(e===2){
                this.columns = this.ncolumns;
            } else if(e===3){
                this.columns = this.ncolumns;
            } else if(e===5){
                this.columns = this.ncolumns;
            } else if(e===4){
                this.columns = this.ncolumns4;
                this.chooicEndTitle = this.$t('eqct.a20');//'选择门';
                this.cateEndTitle   = this.$t('eqct.a57');//'车场道闸设备信息设置';
                this.chooicEndAction  = 1;
            }  else if(e===6){
                this.columns = this.ncolumns;
                this.chooicEndTitle = this.$t('eqct.a20');//'选择门';
                this.cateEndTitle   = this.$t('eqct.a58');//'闸机设备列表';
                this.chooicEndAction  = 1;
            } else if(e===1001){
                this.columns = this.ncolumns7;
                this.cateEndTitle   = this.$t('eqct.a29');//'设备列表';
            } else {
                this.chooicEndTitle = this.$t('eqct.a20');//'选择门';
                this.chooicEndAction  = 1;
                this.columns = this.ncolumns;
            }
            this.chooicBasic.action = this.chooicEndAction;
            this.loadEndEquipmentList();
        },
        chooicEnd(){          
            this.chooicBasic.ends = this.equipmentList;
            this.endTableDialogVisible = true;
        },
        remove(record){
            this.$confirm({
                title: this.$t('eqct.a59'),//'确认要取消该设备的转换信息吗？'
                centered: true,
                onOk: () => {
                    let arr = [];
                    arr.push(record);
                   
                   let param = {
                        action: 1,
                        clientId: this.$store.getters.clientId,
                        sitegrId: this.$store.getters.sitegrId,
                        siteId: this.$route.query.id,
                        iconDeviceType: this.conDeviceType,
                        equipments:arr,
                       
                    };
                    console.log('RemoveEquipmentConvert param ',param);
                    removeEquipmentConvert(param)
                    .then((res)=>{
                        console.log('RemoveEquipmentConvert res ',res);
                        if(res.errorCode=='00' || res.errorCode=='03'){
                            this.$message.success(res.msg);
                            this.loadEndEquipmentList();
                        }else{
                            this.$message.error(res.msg);
                            this.loading = false;
                        }
                    }).catch((err) => {
                        this.loading = false;
                        console.log(err);
                    });
                }
            });
            
        },
        removeAll(){
            this.$confirm({
                title: this.$t('eqct.a60'),//'确认要取消所有的转换信息吗？'
                centered: true,
                onOk: () => {
                    
                    let param = {
                        action: 1,
                        clientId: this.$store.getters.clientId,
                        sitegrId: this.$store.getters.sitegrId,
                        siteId: this.$route.query.id,
                        iconDeviceType: this.conDeviceType,
                        equipments:this.equipmentList,
                       
                    };
                     console.log('RemoveEquipmentConvert param ',param);
                    removeEquipmentConvert(param)
                    .then((res)=>{
                        console.log('RemoveEquipmentConvert res ',res);
                        if(res.errorCode=='00' || res.errorCode=='03'){
                            this.$message.success(res.msg);
                            this.loadEndEquipmentList();
                        }else{
                            this.$message.error(res.msg);
                            this.loading = false;
                        }
                    }).catch((err) => {
                        this.loading = false;
                        console.log(err);
                    });

                }
            });
        },
        removeSelectedRows(){
            this.$confirm({
                title: this.$t('eqct.a61'),//'确认要取消所选择的设备的转换信息吗？',
                centered: true,
                onOk: () => {
                    
                    let param = {
                        action: 1,
                        clientId: this.$store.getters.clientId,
                        sitegrId: this.$store.getters.sitegrId,
                        siteId: this.$route.query.id,
                        iconDeviceType: this.conDeviceType,
                        equipments:this.selectedRows,
                    };
                     console.log('RemoveEquipmentConvert param ',param);
                    removeEquipmentConvert(param)
                    .then((res)=>{
                        console.log('RemoveEquipmentConvert res ',res);
                        if(res.errorCode=='00' || res.errorCode=='03'){
                            this.$message.success(res.msg);
                            this.loadEndEquipmentList();
                            this.selectedRowKeys=[];
                            this.selectedRows=[];
                        }else{
                            this.$message.error(res.msg);
                            this.loading = false;
                        }
                    }).catch((err) => {
                        this.loading = false;
                        console.log(err);
                    });

                }
            });
        },
        tableSize() {
            const indexDom = document.getElementById("equipmentConvert");
            if (indexDom !== null) {
                const height = indexDom.getElementsByClassName("main")?indexDom.getElementsByClassName("main")[0].clientHeight:0;
                console.log("ant-table-thead",indexDom.getElementsByClassName("ant-table-thead"))
                const title  = indexDom.getElementsByClassName("ant-table-thead") && indexDom.getElementsByClassName("ant-table-thead").length>0?indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight:0;
                const tableHeight = height - 210 - title;
                this.size.y = tableHeight;
                console.log('tableHeight',tableHeight)
            }
        },
        handleSubmit(){
            if(this.conDeviceType===4){
                this.$refs.carRuleForm.validate(valid => {
                    if (valid) {
                       this.saveEnd();
                    } else {
                        console.log('error submit!!');
                    }
                });
                
            } else {
                this.saveEnd();
            }
        },
        saveEnd(){
            let vail = false;
            let arr = [];
            
            for (let i = 0; i < this.equipmentList.length; i++) {
                const row = this.equipmentList[i];
                
                if(this.conDeviceType===1){
                    let evail1 =  this.check('',row.faceName,1,50);
                    if(evail1.err===0){
                        row.fcncls = "";
                        row.fcnvaild = false;
                        row.fcnmsg = '';    
                    }else{
                        row.fcncls     = evail1.cls;
                        row.fcnvaild   = evail1.visible;  
                        row.fcnmsg     = evail1.title;   
                        vail = true;
                    }
                    let evail2 =  this.check('',row.faceDeviceKey,1,20);
                    if(evail2.err===0){
                        row.fdkcls = "";
                        row.fdkvaild = false;
                        row.fdkmsg = '';    
                    }else{
                        row.fdkcls     = evail2.cls;
                        row.fdkvaild   = evail2.visible;  
                        row.fdkmsg     = evail2.title;   
                        vail = true;
                    }
                    let evail3 =  this.check('',row.faceDeviceIp,1,20);
                   
                    if(evail3.err===0){
                        row.fdpcls = "";
                        row.fdpvaild = false;
                        row.fdpmsg = '';    
                    }else{
                        row.fdpcls     = evail3.cls;
                        row.fdpvaild   = evail3.visible;  
                        row.fdpmsg     = evail3.title;   
                        vail = true;
                    }

                    let evail4 =  this.check('',row.faceDeviceBrand,1,10);
                    if(evail4.err===0){
                        row.fdbcls = "";
                        row.fdbvaild = false;
                        row.fdbmsg = '';    
                    }else{
                        row.fdbcls     = evail4.cls;
                        row.fdbvaild   = evail4.visible;  
                        row.fdbmsg     = evail4.title;   
                        vail = true;
                    }
                } else {
                    let evail =  this.check('',row.equipmentName,1,50);
                    if(evail.err===0){
                        row.eqncls = "";
                        row.eqnvaild = false;      
                        row.eqnmsg = '';              
                    }else{
                        row.eqncls = evail.cls;
                        row.eqnvaild = evail.visible;  
                        row.eqnmsg = evail.title;   
                        vail = true;
                    }
                }
                console.log("equipment>>",row);
                arr.push(row);
            }

            this.equipmentList = [...arr];
            if(vail){
                return;
            }
            
            let param = {
                action: 1,
                clientId: this.$store.getters.clientId,
                sitegrId: this.$store.getters.sitegrId,
                siteId: this.$route.query.id,
                iconDeviceType: this.conDeviceType,
                equipments:this.equipmentList,
                brand:this.endBrand,
                apiAccount:this.carform.apiAccount,
                apiPassword:this.carform.apiPassword,
                apiURL:this.carform.apiURL,
                appID:this.carform.appID,
                appKey:this.carform.appKey,
                appSync:this.carform.appSync,
                version:this.carform.version,
            };
            console.log('saveEquipmentConvert param',param);
            this.$confirm({
                title: this.$t('eqct.a62'),//'确认要保存所设置的信息吗？',
                centered: true,
                onOk: () => {
                    this.loading = true;
                    saveEquipmentConvert(param).then((res)=>{
                        console.log('SaveEquipmentConvert res ',res);
                        if(res.errorCode=='00' || res.errorCode=='03'){
                            this.$message.success(res.msg);
                            this.loadEndEquipmentList();
                        }else{
                            this.$message.error(res.msg);
                            this.loading = false;
                        }

                    }).catch((err) => {
                        this.loading = false;
                        console.log(err);
                    });
                },
            });

        },
        check(tag,value,min,max){
            let err = 0;
            let msg = '';
            let inputLabel = this.$t('energy.setting.reportGr.inputLabel');// '请输入${label}',
            let spLabel= this.$t('energy.setting.reportGr.spLabel');// '${label}不能包含特殊字符!',
            let lenLabel= this.$t('energy.setting.reportGr.lenLabel');// '${label}长度必须在 ${min} 到 ${max}之间',
            if(value===undefined || value===''){
                err = 1;
                msg = inputLabel.replace('${label}',tag);
            }

            if(err ==0 && min>0&& max>0){
                let len = value.length;
                if( len<min || len>max){
                    err = 1;
                    msg = lenLabel.replace('${label}',tag).replace('${min}',min).replace('${max}',max);
                }
            }

            if(err ==0){
                let bol = this.checkString(value);
                if(bol){
                    err = 1;
                    msg = spLabel.replace('${label}',tag);
                }
            }
        

            
            let visible = err>0;
            let cls = visible?'err':'';
            let verr = {
                cls:cls,
                title:msg,
                visible:visible,
                err:err,
            }

            return verr;
        },
        checkString(value){
            let regEn = /[`~!@$%^&*+<>?:"{},\/;'[\]]/im;
            return regEn.test(value) ;
        },
        onSelectChange(selectedRowKeys,selectedRows){
            this.selectedRowKeys = selectedRowKeys;
            this.selectedRows = selectedRows;
        },
        getPopupContainer(trigger) {
            return trigger.parentElement;
        },
        clearTooltips(){
            let arr = [];
            if( this.equipmentList.length>0){
                for (let i = 0; i < this.equipmentList.length; i++) {
                    const row = this.equipmentList[i];
                    
                    if(this.$parent.conDeviceType==='1'){
                        
                        row.fcncls = "";
                        row.fcnvaild = false;
                        row.fcnmsg = '';    
                    
                        row.fdkcls = "";
                        row.fdkvaild = false;
                        row.fdkmsg = '';    
                    
                        row.fdpcls = "";
                        row.fdpvaild = false;
                        row.fdpmsg = '';    
                    
                        row.fdbcls = "";
                        row.fdbvaild = false;
                        row.fdbmsg = '';    
                       
                    } else {
                        row.eqncls = "";
                        row.eqnvaild = false;      
                        row.eqnmsg = '';              
                       
                    }
                    arr.push(row);
                }
                this.equipmentList = [...arr];
            }

            this.apiURLVail.fcncls      = "";
            this.apiURLVail.fcnvaild    = false;
            this.apiURLVail.fcnmsg      = ''; 
        },
        barriCheckChange(e,row){
            console.log('barriCheckAllChange',e.target.checked,row);
            if(e.target.checked){
                 this.selectedRows.push(row);
            }else{
                let rows = [];
                this.selectedRows.forEach(element => {
                    if(element.key != row.key){
                        rows.push(element);
                    }
                });
                this.selectedRows = rows;
            }

            if(this.selectedRows.length == this.equipmentList.length){
                this.barriCheckAll = true;
            }else{
                this.barriCheckAll = false;
            }
        },
        barriCheckAllChange(e){
            console.log('barriCheckAllChange',e.target.checked);
            this.selectedRows = [];
            this.equipmentList.forEach(element => {
                element.chk = e.target.checked;
                if( e.target.checked){
                    this.selectedRows.push(element);
                }
            });
        },
        iowCheckAllChange(e){
            this.equipmentList.forEach(element => {
                element.iow = e.target.checked;
            });
        },
        vstCheckAllChange(e){
            this.equipmentList.forEach(element => {
                element.vst = e.target.checked;
            });
        },
        atdCheckAllChange(e){
            this.equipmentList.forEach(element => {
                element.atd = e.target.checked;
            });
        },
    },
    computed: {
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: this.onSelectChange,
                hideDefaultSelections: true,
                type:this.checkType,
            };
        },
    },
    components: {
        "my-headertitle": headerTitle,
        "end-td":endTableDialog,
    },
}
</script>
<style scoped>

#equipmentConvert {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-shadow: 10px  10px #bdbcbc;
  background: #ffffff;
  font-family: "Microsoft YaHei";
  overflow: hidden;
}

.header {
  font-size: 18px;
  padding: 10px 20px;
  white-space: nowrap;
  overflow: hidden;
}
.header-title {
  display: flex;
  align-items: center;
}
.header-title span {
  margin-right: 30px;
}
.main{
    width: 100%;
    height: calc(100% - 70px);
    padding: 10px 20px;
    overflow: hidden;
}
.main-top{
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    
}
.main-top-item{
    padding-left: 5px;
}
.main-content{
    width: 100%;
    height: calc(100% - 45px);
    font-size: 14px;
}
.main-content-box1{
    border: 1px solid #f5f5f5;
    width: 100%;
    height: 100%;
}
.main-content-box{
    border: 1px solid #f5f5f5;
    margin: 10px 0;
    width: 100%;
    height: 100%;
}
.main-content-title{
    padding: 10px 5px;
    font-size: 14px;
    background-color: #f5f5f5;
}
.main-content-title span{
     margin: 5px;
}

.main-content-area{
    width: 100%;
    height: calc( 100% - 80px );
    padding: 5px;
    position: relative;
}
.main-content-area-fooler{
     margin: 5px;
}
.main-content-area-row{
     padding: 5px;
}
.main-content-area-row-title{
    padding: 10px 5px;
    font-size: 14px;
}
.main-content-area-col{
     padding: 5px;
}
.main-content-area-col1{
     padding: 0 5px;
}
.dis-between{
    display: flex;
    justify-content: space-between;
}
.err{
    border: 1px solid #ff0000;
}
.mtable{
    width:100%;table-layout: fixed;
}
.mtable thead{background-color: #e8e8e8;}
.mtable thead th{border: 1px solid #e8e8e8;padding:5px;text-align: center;position: sticky;top:-1px;z-index: 10;background-color: #e8e8e8;}
.mtable tr td{border: 1px solid #e8e8e8;padding:5px;text-align: center;}
</style>